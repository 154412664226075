import "./Privacy.css";

function Privacy(props) {
  return (
    <div className="Privacy">
      <div
        className="close-privacy-button"
        onClick={(e) => props.handleTermsClick("close-privacy")}
      >
        CLOSE X
      </div>

      <div className="privacy-inner-positioner">
        <h1>PRIVACY POLICY</h1>
        <h2> www.SellForCash.Online</h2>
        <p>Effective date: May 25, 2023</p>
        <ol type="1">
          <li>
            <h2> </h2>
            <p>
              Welcome to www.SellForCash.Online (the “Site”) which is owned by
              Nomad Creative LLC (“SellForCash.Online”, “we”, “us” and “our”)
              and operated from the United States. This privacy policy has been
              compiled to better serve those who are concerned with how their
              ‘Personally Identifiable Information’ (PII) is being used online.
              PII, as described in US privacy law and information security, is
              information that can be used on its own or with other information
              to identify, contact, or locate a single person, or to identify an
              individual in context. Please read our privacy policy carefully to
              get a clear understanding of how we collect, use, protect, or
              otherwise handle your PII in accordance with our website.
            </p>
          </li>
          {/* ------------------------------------------------------------------ */}

          <li>
            <h2>
              {" "}
              Collection of Personal and Non-Personal Information (PII and
              Non-PII)
            </h2>
            <p>
              We collect and store certain personally identifiable information
              about you (i.e., information which may identify you in some way;
              such as your name, telephone number, email address, screen name)
              (“PII”) only when you voluntarily submit it. We may request that
              you submit PII in certain instances, such as when you use the
              services offered by us, submit comments or questions, request
              information, participate in a promotion, contest or sweepstakes or
              utilize other features or functions of our site. When you connect
              to our Site, there is certain technical, non-personally
              identifiable information which does not identify any individual
              (“Non-PII”) that may be collected and stored through the use of
              “cookie” technology and IP addresses. PII is not extracted in this
              process. Non-PII may include, but is not limited to, your IP host
              address, internet browser type, personal computer settings, and
              the number, duration, and character of page visits. This
              information assists us to update our site so that it remains
              interesting to our visitors and contains content that is tailored
              to visitors’ interests. .
            </p>
          </li>
          {/* ------------------------------------------------------------------ */}

          <li>
            <h2> Use of Your PII. </h2>
            <p>
              When you provide your PII at our Site, we will limit the use of
              the PII for the purpose for which it was collected in accordance
              with the terms of this Privacy Policy. Other limited uses of your
              PII may include:
            </p>
            <ul type="circle">
              <li>
                To respond to your questions, comments, and requests; to provide
                you with access to certain areas and features on our Site; and
                to communicate with you about your activities on our site.
              </li>
              <li>
                To investigate suspected fraud, harassment, physical threats, or
                other violations of any law, rule or regulation, the rules or
                policies of our Site, or the rights of third parties; or to
                investigate any suspected conduct which we deem improper.
              </li>
              <li>
                To help us develop, deliver and improve our services, content
                and advertising.
              </li>
              <li>
                To share with our parent, subsidiary, and affiliated companies,
                and promotional partners involved in creating, producing,
                delivering, or maintaining our Site, as required to perform
                functions on our behalf in connection with the Site (such as
                administration of the Site, administration of promotions or
                other features on the Site, marketing, data analysis, and
                customer services). In order to share such information, it may
                be necessary for us to transmit your PII outside the
                jurisdiction set forth below and you agree to this transfer.
                Further use or disclosure of PII by these parties for other
                purposes is not permitted.
              </li>
              <li>
                To share with third party service providers whom we employ to
                perform functions on our behalf in connection with the Site
                (such functions may include, but are not limited to, sending
                postal mail and e-mail, removing repetitive information from
                customer lists, analyzing data and providing marketing
                assistance, and providing customer service). These third party
                service providers have access to PII that is needed to perform
                their functions, and may collect or store PII as part of their
                performance of these functions, but are not authorized to use it
                for other purposes.
              </li>
              <li>
                For our internal purposes such as auditing, data analysis, and
                research to improve our products, services and customer
                communications.
              </li>
              <li>
                For other purposes as disclosed when your PII is collected or in
                any additional terms and conditions applicable to a particular
                feature of our site.
              </li>
              <li>
                For disclosures required by law, regulation, or court order.
              </li>
              <li>
                For the purpose of or in connection with legal proceedings or
                necessary for establishing, defending, or exercising legal
                rights.
              </li>
              <li>
                In an emergency to protect the health or safety of users of our
                site or the general public, or in the interests of national
                security.
              </li>
            </ul>
            <p>
              Except as provided for herein, we will not provide any of your PII
              to any third parties without your specific consent.
            </p>
          </li>
          {/* ------------------------------------------------------------------ */}

          <li>
            <h2> Managing Your Personal Information. </h2>
            <p>
              You have the ultimate control over the PII that we collect and
              use. You can always chose not to provide certain PII, but please
              keep in mind that you may not be able to use some of the features
              offered by our site unless you provide us with your PII. If you
              wish to verify, update, or correct any of your PII collected
              through our site, contact us on the details provided in the
              contact section of this policy.
            </p>
          </li>
          {/* ------------------------------------------------------------------ */}
          <li>
            <h2> We may also use your PII for the following purposes:</h2>
            <p></p>
          </li>
          {/* ------------------------------------------------------------------ */}
          <li>
            <h2>Direct Marketing. </h2>
            <p>
              We may use your personal information to send you
              SellForCash.Online related marketing communications as permitted
              by law. You will have the ability to opt-out of our marketing and
              promotional communications.
            </p>
          </li>
          {/* ------------------------------------------------------------------ */}
          <li>
            <h2> For research and development. </h2>
            <p>
              We may use your personal information for research and development
              purposes, including to analyze and improve the Service and our
              business.{" "}
            </p>
          </li>
          {/* ------------------------------------------------------------------ */}
          <li>
            <h2>To create anonymous data. </h2>
            <p>
              We may create aggregated, de-identified or other anonymous data
              records from your personal information and other individuals whose
              personal information we collect. We make personal information into
              anonymous data by excluding information (such as your name) that
              makes the data personally identifiable to you. We may use this
              anonymous data and share it with third parties for our lawful
              business purposes, including to analyze and improve the Service
              and promote our business.
            </p>
          </li>
          {/* ------------------------------------------------------------------ */}
          <li>
            <h2> To comply with laws and regulations. </h2>
            <p>
              {" "}
              We use your personal information as we believe necessary or
              appropriate to comply with applicable laws, lawful requests, and
              legal process, such as to respond to subpoenas or requests from
              government authorities.{" "}
            </p>
          </li>
          {/* ------------------------------------------------------------------ */}
          <li>
            <h2>For compliance, fraud prevention and safety. </h2>
            <p>
              We may use your personal information and disclose it to law
              enforcement, government authorities, and private parties as we
              believe necessary or appropriate to: (a) protect our, your or
              others’ rights, privacy, safety or property (including by making
              and defending legal claims); (b) audit our internal processes for
              compliance with legal and contractual requirements; (c) enforce
              the terms and conditions that govern the Service; and (d) protect,
              investigate and deter against fraudulent, harmful, unauthorized,
              unethical or illegal activity, including cyber-attacks and
              identity theft.
            </p>
          </li>
          {/* ------------------------------------------------------------------ */}
          <li>
            <h2>Cookies </h2>
            <p>
              This Cookie Policy explains how Nomad Creative LLC
              (“SellForCash.Online”, “we”, “us” or “our”) uses cookies and
              similar technologies in connection with the www.SellForCash.Online
              website.
            </p>
            <h2>What are cookies?</h2>
            <p>
              Cookies are small text files placed on your computer by websites
              and sometimes by emails. They provide useful information to
              organizations, which helps to make your visits to their websites
              more effective and efficient. We use cookies to ensure that we are
              able to understand how you use our websites and to ensure that we
              can make improvements to the websites. Cookies do not contain any
              personal or confidential information about you.
            </p>

            <h2> How we use cookies </h2>
            <p>
              We use cookies to ensure that you get the best from our website.
              The first time that you visit our website you will be asked to
              consent to our use of cookies and we suggest that you agree to
              allow the cookies to be active on your device whilst you visit and
              browse our website to ensure that you experience our website
              fully. The types of cookies that we may use include:
            </p>
            <ul type="circle">
              <h2>Session cookies </h2>
              <p>
                Session cookies last only for the duration of your visit and are
                deleted when you close your browser. These facilitate various
                tasks such as allowing a website to identify that a user of a
                particular device is navigating from page to page, supporting
                website security or basic functionality.
              </p>

              <h2>Persistent cookies</h2>
              <p>
                Persistent cookies last after you have closed your browser, and
                allow a website to remember your actions and preferences.
                Sometimes persistent cookies are used by websites to provide
                targeted advertising based upon the browsing history of the
                device. We use persistent cookies to allow us to analyze users
                visit to our site. These cookies help us to understand how
                customers arrive at and use our site so we can improve the
                overall service.
              </p>
              <h2>Strictly necessary cookies</h2>
              <p>
                These cookies are essential in order to enable you to move
                around the website and use its features, and ensuring the
                security of your experience. Without these cookies services you
                have asked for, such as applying for products and managing your
                accounts, cannot be provided. These cookies don’t gather
                information about you for the purposes of marketing.
              </p>
              <h2>Performance cookies</h2>
              <p>
                These cookies collect information about how visitors use a web
                site, for instance which pages visitors go to most often, and if
                they get error messages from web pages. All information these
                cookies collect is only used to improve how a website works, the
                user experience and to optimize our advertising. By using our
                websites you agree that we can place these types of cookies on
                your device, however you can block these cookies using your
                browser settings.{" "}
              </p>
              <h2> Functionality cookies</h2>
              <p>
                These cookies allow the website to remember choices you make
                (such as your username). The information these cookies collect
                is anonymized (i.e. it does not contain your name, address etc.)
                and they do not track your browsing activity across other
                websites. By using our websites you agree that we can place
                these types of cookies on your device, however you can block
                these cookies using your browser settings.{" "}
              </p>
              <h2> Targeting cookies</h2>
              <p>
                These cookies collect several pieces of information about your
                browsing habits. [They are usually placed by third party
                advertising networks]. They remember that you have visited a
                website and this information is shared with other organizations
                such as media publishers. These organizations do this in order
                to provide you with targeted adverts more relevant to you and
                your interests.
              </p>
              <h2>Third party cookies</h2>
              <p>
                Please note that third parties (including, for example,
                advertising networks and providers of external services like web
                traffic analysis services) may also use cookies, over which we
                have no control. These cookies are likely to be
                analytical/performance cookies or targeting cookies.
              </p>
            </ul>
            <h2>Managing Cookies</h2>
            <p>
              You can control and/or delete cookies as you wish – for details,
              see aboutcookies.org. You can delete all cookies that are already
              on your computer and you can set most browsers to prevent them
              from being placed. If you do this, however, you may have to
              manually adjust some preferences every time you visit our website
              or use our Platform and some services and functionalities we offer
              may not work. To restrict or handle cookies, please see the ‘Help’
              section of your internet browser. For any further information,
              please contact us at contact@SellForCash.Online
            </p>
          </li>
          {/* ------------------------------------------------------------------ */}
          <li>
            <h2>Security </h2>
            <p>
              We adopt appropriate data collection, storage and processing
              practices and security measures to protect against unauthorized
              access, alteration, disclosure or destruction of your personal
              information, username, password, transaction information and data
              stored on our Site. The security on the internet is not 100%
              guaranteed, however we use best efforts to keep it secure for the
              users. We do not accept liability for unintentional disclosure. We
              encourage you to use caution when using the Internet.
            </p>
          </li>
          {/* ------------------------------------------------------------------ */}

          <li>
            <h2> Your California privacy rights. </h2>
            <p>
              The CCPA grants California residents the following rights.
              However, these rights are not absolute, and in certain cases we
              may decline your request as permitted by law.
            </p>
            <ul type="circle">
              <h2>Information. </h2>
              <p>
                The CCPA grants California residents the following rights.
                However, these rights are not absolute, and in certain cases we
                may decline your request as permitted by law.
              </p>
              <li>
                The categories of Personal Information that we have collected.
              </li>
              <li>
                The categories of sources from which we collected Personal
                Information
              </li>
              <li>
                The business or commercial purpose for collecting and/or selling
                Personal Information.
              </li>
              <li>
                The categories of third parties with whom we share Personal
                Information.
              </li>
              <li>
                {" "}
                Whether we have disclosed your Personal Information for a
                business purpose, and if so, the categories of Personal
                Information received by each category of third party recipient.
              </li>
              <li>
                Whether we’ve sold your Personal Information, and if so, the
                categories of Personal Information received by each category of
                third party recipient.
              </li>
            </ul>
            <h2> Access. </h2>
            <p>
              You can request a copy of the Personal Information that we have
              collected about you during the past 12 months.
            </p>
            <h2> Deletion. </h2>
            <p>
              You can ask us to delete the Personal Information that we have
              collected from you.
            </p>
          </li>
          <p>
            You are entitled to exercise the rights described above free from
            discrimination in the form of legally prohibited increases in the
            price or decreases in the quality of our Service.
          </p>

          {/* ------------------------------------------------------------------ */}

          <li>
            <h2>How to exercise your California rights</h2>
            <p>
              You may exercise your California privacy rights described above as
              follows:
            </p>
            <ul type="circle">
              <li>
                <h2>Right to information, access and deletion. </h2>
                <p>
                  You can request to exercise your information, access and
                  deletion rights, by emailing contact@SellForCash.Online. We
                  reserve the right to confirm your California residence to
                  process your requests and will need to confirm your identity
                  to process your requests to exercise your information, access
                  or deletion rights. As part of this process, government
                  identification may be required. Consistent with California
                  law, you may designate an authorized agent to make a request
                  on your behalf. In order to designate an authorized agent to
                  make a request on your behalf, you must provide a valid power
                  of attorney, the requester’s valid government-issued
                  identification, and the authorized agent’s valid government
                  issued identification. We cannot process your request if you
                  do not provide us with sufficient detail to allow us to
                  understand and respond to it.
                </p>
              </li>
              <li>
                <h2>Request a list of third party marketers. </h2>
                <p>
                  California’s “Shine the Light” law (California Civil Code §
                  1798.83) allows California residents to ask companies with
                  whom they have formed a business relationship primarily for
                  personal, family or household purposes to provide certain
                  information about the companies’ sharing of certain personal
                  information with third parties for their direct marketing
                  purposes during the preceding year (if any). You can submit
                  such a request by sending an email to
                  contact@SellForCash.Online with “Shine the Light” in the
                  subject line. The request must include your current name,
                  street address, city, state, and zip code and attest to the
                  fact that you are a California resident.
                </p>
              </li>
            </ul>
            <p>
              We cannot process your request if you do not provide us with
              sufficient detail to allow us to understand and respond to it.
            </p>
          </li>
          {/* ------------------------------------------------------------------ */}

          <li>
            <h2>Sharing your personal information </h2>
            <p>
              We do not sell, trade, or rent Users personal identification
              information to others in accordance with the CCPA. We may share
              generic aggregated demographic information not linked to any
              personal identification information regarding visitors and users
              with our business partners, trusted affiliates and advertisers for
              the purposes outlined above.
            </p>
          </li>
          {/* ------------------------------------------------------------------ */}

          <li>
            <h2>Children </h2>
            <p>
              SellForCash.Online is not intended for use by children under 13
              years of age, and we do not knowingly collect information about
              children under age 13 through the Service. If we learn that we
              have collected personal information of a child without the consent
              of the child’s parent or guardian as required by law, we will
              delete it. We aim to comply with the COPPA.
            </p>
          </li>
          {/* ------------------------------------------------------------------ */}

          <li>
            <h2>Third party websites </h2>
            <p>
              Users may find advertising or other content on our Site that link
              to the sites and services of our partners, suppliers, advertisers,
              sponsors, licensors and other third parties. We do not control the
              content or links that appear on these sites and are not
              responsible for the practices employed by websites linked to or
              from our Site. In addition, these sites or services, including
              their content and links, may be constantly changing. These sites
              and services may have their own privacy policies and customer
              service policies. Browsing and interaction on any other website,
              including websites which have a link to our Site, is subject to
              that website's own terms and policies.
            </p>
          </li>
          {/* ------------------------------------------------------------------ */}

          <li>
            <h2> Data Retention</h2>
            <p>
              We will retain your information for as long as needed to provide
              you the Site services, for our valid business purposes, and as
              necessary to comply with our legal obligations, resolve disputes,
              defend our legal rights, and enforce our agreements.
            </p>
          </li>
          {/* ------------------------------------------------------------------ */}

          <li>
            <h2> Changes</h2>
            <p>
              SellForCash.Online has the discretion to update this privacy
              policy at any time. When we do, we will revise the updated date at
              the bottom of this page. We encourage Users to frequently check
              this page for any changes to stay informed about how we are
              helping to protect the personal information we collect. You
              acknowledge and agree that it is your responsibility to review
              this privacy policy periodically and become aware of
              modifications.
            </p>
          </li>
          {/* ------------------------------------------------------------------ */}

          <li>
            <h2> Acceptance</h2>
            <p>
              By using this Site, you signify your acceptance of this policy. If
              you do not agree to this policy, please do not use our Site. Your
              continued use of the Site following the posting of changes to this
              policy will be deemed your acceptance of those changes.
            </p>
          </li>
          {/* ------------------------------------------------------------------ */}

          <li>
            <h2> Contacting us</h2>
            <p>
              If you have any questions about this Privacy Policy, the practices
              of this site, or your dealings with this site, please contact us
              at: contact@SellForCash.Online
            </p>
          </li>
          {/* ------------------------------------------------------------------ */}

          <li>
            <h2>Nomad Creative LLC </h2>
            <p>Email: contact@SellForCash.Online</p>
          </li>
          {/* ------------------------------------------------------------------ */}
        </ol>
      </div>
    </div>
  );
}

export default Privacy;
