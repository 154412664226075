import { createSlice, createListenerMiddleware } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";

// call setFormData more often to ensure that the data is being written to the DB if async occurs

const initialState = {
  //database id
  id: "",
  count: 0,
  name: "",
  manualName: "",
  apiOwnerName: "",
  autoCompleteName: "",
  email: "",
  phone: "",
  street: "",
  manualStreet: "",
  editedStreet: "",
  city: "",
  zip: "",
  apiEquity: "",
  apiPercentage: "",
  apiHomeValue: "",
  apiMaxHomeValue: "",
  phones: [],
  dynamicHeadline: "",
  dynamicSubHeadline: "",
  thankYouHeadline: "",
  thankYouSubHeadline: "",
  submitting: false,
  submitted: false,
  submissionError: null,
  recordSubmitting: false,
  recordSubmitted: false,
  propertyRecord: null,
  addressSelectionType: "Manual", //Google, Autocomplete, or Manual
  url: "",
  trafficSource: "",
  campaignId: "",
  campaignName: "",
  adgroupdId: "",
  adgroupName: "",
  keyword: "",
  gclid: "",
  device: "",

  verifyPhone: false, //tells the crm that is should send a verification code.
  verificationCode: "",
  verificationCodeError: null,
  phoneVerified: false,

  isPropertyOwner: "true",
  needRepairs: "No",
  propertyType: "",
  workingWithAgent: "No",

  homeType: "",
  remainingMortgage: "1000",

  bedrooms: "",
  bathrooms: "",
  floors: "",
  finishedSquareFootage: "1000",
  basementSquareFootage: "1000",
  howSoonSell: "",
  reasonForSelling: "",
  garage: "",
  garageCars: "",
  hasHoa: "",
  hasSolar: "",
  planningToBuy: "",
  septicOrSewer: "",
  knownIssues: "",

  qualifyingQuestionStep: 1,

  wantToSetAppointment: "",
  selectedAppointmentDate: "",
  selectedAppointmentTime: "",
};

const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    formDataChanged: (state, action) => {
      console.log("Form data changed: ", action.payload);
      return { ...state, ...action.payload };
    },
    formSubmitted: (state, action) => {
      console.log("Form submitted: ", action.payload);
      return { ...state, ...action.payload, submitting: true };
    },
    leadDataChanged: (state, action) => {
      console.log("Lead data changed: ", action.payload);
      return { ...state, ...action.payload };
    },
    recordChanged: (state, action) => {
      console.log("Record changed: ", action.payload);
      return { ...state, recordSubmitting: false, ...action.payload };
    },
    formSubmissionSuccess: (state, action) => {
      return {
        ...state,
        ...action.payload,
        submitting: false,
        submitted: true,
      };
    },
    formSubmissionError: (state, action) => {
      return {
        ...state,
        ...action.payload,
        submitting: false,
        submitted: false,
      };
    },
    recordSubmissionSuccess: (state, action) => {
      return {
        ...state,
        ...action.payload,
        recordSubmitting: false,
        recordSubmitted: true,
      };
    },
    recordSubmissionError: (state, action) => {
      return {
        ...state,
        ...action.payload,
        recordSubmitting: false,
        recordSubmitted: false,
      };
    },
    verifyCode: (state, action) => {
      return { ...state, ...action.payload };
    },
    verifyCodeSuccess: (state, action) => {
      return { ...state, ...action.payload, verificationCodeError: null };
    },
    verifyCodeError: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const {
  formDataChanged,
  formSubmitted,
  leadDataChanged,
  recordChanged,
  formSubmissionSuccess,
  formSubmissionError,
  recordSubmissionSuccess,
  recordSubmissionError,
  verifyCode,
  verifyCodeSuccess,
  verifyCodeError,
} = formSlice.actions;

// Create the middleware instance and methods. This is registered in the index.js configureStore() call.
export const formListeners = createListenerMiddleware();

// Add one or more listener entries that look for specific actions.
// They may contain any sync or async logic, similar to thunks.
formListeners.startListening({
  //This is called anytime the form data changes.
  actionCreator: formSubmitted,
  effect: async (action, listenerApi) => {
    // Run whatever additional side-effect-y logic you want here
    const formData = listenerApi.getState().form;
    console.log(
      "Form form submitted: action: ",
      action.payload,
      ", state: ",
      formData
    );
    //form validation checks
    const { phone, street, name } = action.payload;
    //We only want to update the partial when these values have changed.
    if (!phone && !street) {
      //If we've submitted the form before and the name has changed, we want to update the lead.
      if (!name || !formData.submitted) return;
    }
    await createUpdateLead(formData, listenerApi.dispatch);
  },
});

formListeners.startListening({
  actionCreator: leadDataChanged,
  effect: async (action, listenerApi) => {
    const formData = listenerApi.getState().form;
    const { phone } = formData;
    if (!phone) return;
    await createUpdateLead(formData, listenerApi.dispatch);
  },
});

formListeners.startListening({
  actionCreator: verifyCode,
  effect: async (action, listenerApi) => {
    const formData = listenerApi.getState().form;
    const { verificationCode, id, userId } = formData;
    if (!verificationCode) return;
    await verifyPhoneCode(verificationCode, id, userId, listenerApi.dispatch);
  },
});

formListeners.startListening({
  actionCreator: formSubmissionSuccess,
  effect: async (action, listenerApi) => {
    console.log("Form submission success: ", action.payload);
    const formData = listenerApi.getState().form;
    const { propertyRecord, recordSubmitting, recordSubmitted } = formData;
    if (!propertyRecord || recordSubmitting || recordSubmitted) return;
    console.log("Propert record", propertyRecord);
    listenerApi.dispatch(recordChanged({ recordSubmitting: true }));
    await createUpdateLead(formData, listenerApi.dispatch);
  },
});
let isLoadingAPI = false;

function jsonToText(obj, indent = '') {
  let result = '';

  // Check if the input is an array
  if (Array.isArray(obj)) {
    for (let item of obj) {
      result += jsonToText(item, indent);
    }
  } else if (typeof obj === 'object' && obj !== null) {
    for (let key in obj) {
      if (Array.isArray(obj[key])) {
        result += `${indent}${key}:\n`;
        result += jsonToText(obj[key], indent + '  ');
      } else if (typeof obj[key] === 'object') {
        result += `${indent}${key}:\n`;
        result += jsonToText(obj[key], indent + '  ');
      } else {
        result += `${indent}${key}: ${obj[key]}\n`;
      }
    }
  } else {
    result += `${indent}${obj}\n`;
  }

  return result;
}

function jsonToHTML(obj) {
  if (Array.isArray(obj)) {
    const items = obj.map(item => `<div class="json-array">${jsonToHTML(item)}</div>`).join('');
    return `<div class="json-object">[${items}]</div>`;
  } else if (typeof obj === 'object' && obj !== null) {
    return Object.entries(obj)
      .map(([key, value]) => {
        return `
                  <div class="json-object">
                      <span class="json-key">${key}:</span>
                      <span class="json-value">${jsonToHTML(value)}</span>
                  </div>
              `;
      })
      .join('');
  } else {
    return `<span class="json-value">${obj}</span>`;
  }
}

const createUpdateLead = async (formData, dispatch) => {
  try {
    console.log("updating lead...");

    const firstName = formData.name.split(" ")[0];
    let lastName = formData.name.split(" ")[1];

    if (lastName === undefined) {
      lastName = firstName;
    }

    if (formData.qualifyingQuestionStep === 1 && formData.name && formData.street && formData.addressSelectionType && !localStorage.getItem("leadId")) {
      if (isLoadingAPI) {
        return;
      }
      isLoadingAPI = true;
      const data = await axios.post(
        "https://sellforcashapi-client37.calvist.com/api/zoho-lead",
        {
          First_Name: firstName,
          Street: formData.street,
          addressSelectionType: formData.addressSelectionType,
          Last_Name: lastName,
          Company: firstName,
          Phone: formData.phone,
          propertyRecord: jsonToText(formData.propertyRecord),
          // start property api details
          apiOwnerName: formData.apiOwnerName,
          apiEquity: formData.apiEquity,
          apiPercentage: formData.apiPercentage,
          apiHomeValue: formData.formattedApiEstimatedValue,
          apiMaxHomeValue: formData.formattedApiMaxValue,
          phones: formData.phones,
          Zip_Code: formData.propertyRecord.PropertyAddress.Zip,
          // end property api details
        },
      ).then((response) => {
        isLoadingAPI = false;
        console.log("Lead created: ", response.data);
        const leadId = response.data.data[0].details.id;
        localStorage.setItem("leadId", leadId);
        dispatch(formSubmissionSuccess({ id: leadId }));
      });

      //console.log("JsonText: ", jsonToText(formData.propertyRecord));
      //console.log("Json HTml: ", jsonToHTML(formData.propertyRecord));

    } else if (formData.qualifyingQuestionStep >= 2 && formData.qualifyingQuestionStep < 23 && localStorage.getItem("leadId")) {
      const leadId = localStorage.getItem("leadId");
      if (!leadId) {
        console.error("No lead id found in local storage");
        return;
      }
      const { data } = await updateZohoLead(leadId, formData);
      console.log("Lead updated: ", data);
      dispatch(formSubmissionSuccess({ id: data.id }));
    } else if (formData.qualifyingQuestionStep === 23 && localStorage.getItem("leadId")) {
      const leadId = localStorage.getItem("leadId");
      const { data } = await updateZohoLead(leadId, formData);
      console.log("Final Lead updated: ", data);
      localStorage.removeItem("leadId");
    }
  } catch (error) {
    dispatch(formSubmissionError({ submissionError: error }));
    console.error("Failed to send form data to api server ", error);
  }
};

function formatDollarAmount(amount) {
  if (isNaN(amount)) {
    return 1000;
  }

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(amount);
}

const updateZohoLead = async (leadId, formData) => {

  const firstName = formData.name.split(" ")[0];
  let lastName = formData.name.split(" ")[1];

  if (lastName === undefined) {
    lastName = firstName;
  }

  return await axios.put(
    `https://sellforcashapi-client37.calvist.com/api/zoho-lead/${leadId}`,
    {
      First_Name: firstName,
      Street: formData.street,
      addressSelectionType: formData.addressSelectionType,
      Last_Name: lastName,
      Company: firstName,
      Phone: formData.phone,
      propertyRecord: jsonToText(formData.propertyRecord),
      // start property api details
      apiOwnerName: formData.apiOwnerName,
      apiEquity: formData.apiEquity,
      apiPercentage: formData.apiPercentage,
      apiHomeValue: formData.formattedApiEstimatedValue,
      apiMaxHomeValue: formData.formattedApiMaxValue,
      phones: formData.phones,
      Email: formData.email,
      //cookie: formData.userId,
      City: formData.city,
      Zip_Code: formData.propertyRecord.PropertyAddress.Zip,
      Country: formData.country,
      leadType: "ReSeller",
      State: "GA",
      Website: formData.url,
      userInputtedStreet: formData.manualStreet,
      dynamicHeadline: formData.dynamicHeadline,
      dynamicSubHeadline: formData.dynamicSubHeadline,
      thankYouHeadline: formData.thankYouHeadline,
      thankYouSubHeadline: formData.thankYouSubHeadline,
      autoCompleteName: formData.autoCompleteName,
      manualName: formData.manualName,
      campaignName: formData.campaignName,
      adgroupName: formData.adgroupName,
      //keyword: formData.keyword,
      //campaignId: formData.campaignId,
      //adgroupId: formData.adgroupId,
      device: formData.device,
      //gclid: formData.gclid,
      trafficSource: formData.trafficSource,
      verifyPhone: formData.verifyPhone,
      isPropertyOwner: formData.isPropertyOwner,
      needRepairs: formData.needRepairs,
      propertyType: formData.propertyType,
      workingWithAgent: formData.workingWithAgent,
      homeType: formData.homeType,
      bedrooms: formData.bedrooms,
      bathrooms: formData.bathrooms,
      floors: formData.floors,
      remainingMortgage: formatDollarAmount(formData.remainingMortgage || 1000),
      finishedSquareFootage: (formData.finishedSquareFootage || 1000) + " sq ft",
      basementSquareFootage: (formData.basementSquareFootage || 1000) + " sq ft",
      howSoonSell: formData.howSoonSell,
      reasonForSelling: formData.reasonForSelling,
      garage: formData.garage,
      garageCars: formData.garageCars,
      hasHoa: formData.hasHoa,
      hasSolar: formData.hasSolar,
      planningToBuy: formData.planningToBuy,
      septicOrSewer: formData.septicOrSewer,
      knownIssues: formData.knownIssues,
      qualifyingQuestionStep: String(formData.qualifyingQuestionStep),
      wantToSetAppointment: formData.wantToSetAppointment,
      selectedAppointmentDate: formData.selectedAppointmentDate,
      selectedAppointmentTime: formData.selectedAppointmentTime,
    }
  );
};

const verifyPhoneCode = async (code, userId, cookie, dispatch) => {
  try {
    console.log("Verifying code...");
    const { data } = await axios.post(
      "https://leads.goinsightmarketing.com/api/re/verifycode",
      {
        code: code,
        userId: userId,
        cookie: cookie,
      }
    );
    console.log("Code verified: ", data);
    dispatch(verifyCodeSuccess({ phoneVerified: true }));
  } catch (error) {
    dispatch(verifyCodeError({ verificationCodeError: error?.response?.data }));
  }
};

export default formSlice.reducer;